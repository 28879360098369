import React from "react"
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import FormatDate  from '../components/formatDate'
import Button from "../components/button"
import Boop from "../components/boop"
import { Fade } from "react-reveal"
import ArrowLeft from "../assets/images/arrow-left.svg"
import ArrowRight from "../assets/images/arrow-right.svg"
import Seo from "../components/seo"

const Flex = styled.div`
  position:relative;    
  margin:0 auto;
  max-width:1150px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction:row;
  padding-bottom: 10rem;
  gap:5rem;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction:column-reverse;
    gap:2rem;
  } 
  h1 {
    font-size:5.7rem;
  }
`

const IllustrationDisque = styled.div`   
    margin-top:1rem;
    width:100%;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
    max-width:550px;
    margin-top:-5rem;
    position:sticky;
    top:2rem;
    
} 
.imageDisque {box-shadow: 0 8px 15px 0 ${props => props.theme.colors.gray};}
`

const ContentDisque = styled.div`   
    width:100%;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
    max-width:727px;
} `


const ButtonWrapper = styled.div`   
    margin-top:1rem;
    margin-bottom:1rem;
    a {margin-bottom:1rem;}
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
   
    } 
`

const NavContainer = styled.div`   
  margin:0 auto;    
  margin-top:0rem;
  margin-bottom:5rem;
  display:flex;
  justify-content:center;
  gap:4rem;
  .nav-disque {
    display:flex;
    flex-direction:column;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration:none;
    color:${props => props.theme.colors.gray};
    &:hover {
      color:${props => props.theme.colors.primary};
    }
  }
  @media (min-width: ${props => props.theme.breakpoints[0]}) {
  
  } 
`



const Disque = ({data, pageContext}) => {
  const {  next, previous } = pageContext
  
  return (
  <Layout>
    <Seo meta={data.disque.seoMetaTags} />
    <h1 className="breadcrumb">Discographie</h1>
      <div className="container">
          <Flex> 
            
            <ContentDisque>
             {/*   <span className="date-small gray"><FormatDate date={data.disque.date} formatDate="yyyy"/></span>*/}
                <Fade up duration={700} distance="70px"><h1>{data.disque.titre}</h1></Fade>
                <ButtonWrapper>
                {data.disque.lienPhysique && <Button external to={data.disque.lienPhysique}>Acheter l'album physique</Button> }
                {data.disque.lienPhysique && data.disque.lienNumerique && " "}

                {data.disque.lienNumerique && <Button external to={data.disque.lienNumerique}>Acheter l'album numérique</Button> }
                </ButtonWrapper>
                <div dangerouslySetInnerHTML={{__html: data.disque.detail}} />
               
                
            </ContentDisque> 
            <IllustrationDisque> 
              <Fade down duration={700} distance="70px">
                <GatsbyImage alt={data.disque.titre} image={data.disque.image.gatsbyImageData} className="imageDisque"/>
                <span className="date-small gray"><FormatDate date={data.disque.date} formatDate="yyyy"/></span>

              </Fade>
            </IllustrationDisque> 
          </Flex>
      </div>
      <div  className="container">
        <NavContainer>
      {previous && <Link className="nav-disque left" to={`/discographie/${previous.slug}`} >
         <Boop x={-15} timing={150} ><ArrowLeft/></Boop>
          <span style={{'textAlign':'right', 'marginTop': '-10px'}}>Précédent</span></Link>}
      {next && <Link className="nav-disque right" to={`/discographie/${next.slug}`}> 
      <Boop x={15} timing={150} ><ArrowRight/></Boop> <span style={{'textAlign':'left', 'marginTop': '-10px'}}>Suivant</span></Link>}
      </NavContainer>
      </div>

  </Layout>
  )
}

export default Disque


export const disqueQuery = graphql`
   query($slug: String!){
    disque:datoCmsDisque( slug: { eq: $slug }) {
          seoMetaTags  {
            ...GatsbyDatoCmsSeoMetaTags
          }    
          id
          titre
          date
          detail
          slug
          lienPhysique
          lienNumerique
          image {
            gatsbyImageData (
              placeholder: BLURRED
            )
            alt
          }
    }

    
  }
`;